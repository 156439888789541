import React from 'react';
import { DateTime } from 'luxon';
import { clsx } from 'clsx';

const Concerts = () => {
  const arData = [
  {
    "date": DateTime.fromFormat('09/02/2024', 'dd/MM/yyyy'),
    "titre": "Fanfarodoï",
    "ville": "Strasbourg (67)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('11/04/2024', 'dd/MM/yyyy'),
    "titre": "Republic Corner",
    "ville": "Poitiers (86)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('12/04/2024', 'dd/MM/yyyy'),
    "titre": "Les Carmes",
    "ville": "La Rochefoucauld-en-Angoumois (16)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('10/05/2024', 'dd/MM/yyyy'),
    "titre": "Nuits Sonores",
    "ville": "Lyon (69)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('17/05/2024', 'dd/MM/yyyy'),
    "titre": "Blizz'Art",
    "ville": "Ciral (80)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('18/05/2024', 'dd/MM/yyyy'),
    "titre": "Baule d'air",
    "ville": "Baule (45)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('19/05/2024', 'dd/MM/yyyy'),
    "titre": "FIMU",
    "ville": "Belfort (90)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('01/06/2024', 'dd/MM/yyyy'),
    "titre": "Festival des fanfares",
    "ville": "Montpellier (34)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('07/06/2024', 'dd/MM/yyyy'),
    "titre": "Zéphyr Festival",
    "ville": "Saint-Jean-de-Bournay (38)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('14/06/2024', 'dd/MM/yyyy'),
    "titre": "V&B",
    "ville": "Sallanches (74)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('15/06/2024', 'dd/MM/yyyy'),
    "titre": "Délices Perchés",
    "ville": "Notre-Dame-de-Mésage (38)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('21/06/2024', 'dd/MM/yyyy'),
    "titre": "Le Trankilou",
    "ville": "Grenoble (38)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('26/06/2024', 'dd/MM/yyyy'),
    "titre": "Guinguette Ensablée",
    "ville": "St-Hilaire-de-Riez (85)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('27/06/2024', 'dd/MM/yyyy'),
    "titre": "DoxArt festival",
    "ville": "Hambye",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('29/06/2024', 'dd/MM/yyyy'),
    "titre": "Entre Rhône et Saône",
    "ville": "Lyon (69)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('02/07/2024', 'dd/MM/yyyy'),
    "titre": "TBA",
    "ville": "",
    "pays": ""
  },
  {
    "date": DateTime.fromFormat('05/07/2024', 'dd/MM/yyyy'),
    "titre": "Festifuries",
    "ville": "Saint-Clément-les-Places (69)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('06/07/2024', 'dd/MM/yyyy'),
    "titre": "Concert",
    "ville": "Feillens (01)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('12/07/2024', 'dd/MM/yyyy'),
    "titre": "TBA",
    "ville": "",
    "pays": ""
  },
  {
    "date": DateTime.fromFormat('13/07/2024', 'dd/MM/yyyy'),
    "titre": "Festiv'Haul",
    "ville": "Catteville (50)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('17/07/2024', 'dd/MM/yyyy'),
    "titre": "Jazz à Millau",
    "ville": "Millau (12)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('20/07/2024', 'dd/MM/yyyy'),
    "titre": "Musi'Plagne",
    "ville": "La Plagne (73)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('22/07/2024', 'dd/MM/yyyy'),
    "titre": "Fête Votive",
    "ville": "Pujaut (30)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('03/08/2024', 'dd/MM/yyyy'),
    "titre": "The Canteen",
    "ville": "Bristol (UK)",
    "pays": "UK"
  },
  {
    "date": DateTime.fromFormat('04/08/2024', 'dd/MM/yyyy'),
    "titre": "Valley Fest",
    "ville": "Bristol (UK)",
    "pays": "UK"
  },
  {
    "date": DateTime.fromFormat('06/08/2024', 'dd/MM/yyyy'),
    "titre": "TBA",
    "ville": "",
    "pays": "UK"
  },
  {
    "date": DateTime.fromFormat('07/08/2024', 'dd/MM/yyyy'),
    "titre": "TBA",
    "ville": "",
    "pays": "UK"
  },
  {
    "date": DateTime.fromFormat('08/08/2024', 'dd/MM/yyyy'),
    "titre": "Boomtown Fair",
    "ville": "Winchester (UK)",
    "pays": "UK"
  },
  {
    "date": DateTime.fromFormat('09/08/2024', 'dd/MM/yyyy'),
    "titre": "Boomtown Fair",
    "ville": "Winchester (UK)",
    "pays": "UK"
  },
  {
    "date": DateTime.fromFormat('10/08/2024', 'dd/MM/yyyy'),
    "titre": "Hootananny Brixton",
    "ville": "London (UK)",
    "pays": "UK"
  },
  {
    "date": DateTime.fromFormat('23/08/2024', 'dd/MM/yyyy'),
    "titre": "Chahut dans le Ciel",
    "ville": "Lons le Saunier (39)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('24/08/2024', 'dd/MM/yyyy'),
    "titre": "Là Haut Si J'y Suis",
    "ville": "Nonette (63)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('21/09/2024', 'dd/MM/yyyy'),
    "titre": "Suivez les Regards",
    "ville": "Pont-à-Mousson (54)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('22/09/2024', 'dd/MM/yyyy'),
    "titre": "Suivez les Regards",
    "ville": "Pont-à-Mousson (54)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('05/10/2024', 'dd/MM/yyyy'),
    "titre": "Les Zythonautes",
    "ville": "Valence (26)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('21/11/2024', 'dd/MM/yyyy'),
    "titre": "Bellevilloise",
    "ville": "Paris (75)",
    "pays": "FR"
  },
  {
    "date": DateTime.fromFormat('30/11/2024', 'dd/MM/yyyy'),
    "titre": "Marché Gare",
    "ville": "Lyon (69)",
    "pays": "FR"
  }
]
  ;

  return (
    <div className="dateTables-parent">
      <table className="dateTables">
        {arData.sort((d1, d2) => -d1.date.toMillis() + d2.date.toMillis()).map(({
                                                                                  date, titre, ville, pays,
                                                                                }, index) => (
          <tr key={index} className={clsx(date < DateTime.local() && 'dateTables-passed')}>
            <td>{date.toFormat('dd/MM/yyyy')}</td>
            <td>{titre}</td>
            <td>{ville}</td>
            <td>{pays}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default Concerts;
