import React from 'react';
import Accueil from './Accueil';
import {BrowserRouter} from "react-router-dom";
import {Routes, Route} from "react-router";

const GotoUrl = ({url, texte}) => {
  window.location.href = url;
  return <div style={{textAlign: "center", padding: "4rem"}}>{texte}</div>
}


const App = () => <BrowserRouter>
  <Routes>
    <Route path="/" element={ <Accueil /> } />
     <Route path="ft" element={<GotoUrl texte="Chargement de la fiche technique" url="https://drive.google.com/file/d/1fAWR1H-c96T6Fx0gafiL17KTS1uxhqIq/view?usp=drive_link"/>} />
     <Route path="ft-en" element={<GotoUrl texte="Loading technical specs" url="https://drive.google.com/file/d/1o9d0NFAS_lR6qMJHPwtUgyJ--SQhaOWB/view?usp=sharing"/>} />
  </Routes>
</BrowserRouter>;



export default App;
